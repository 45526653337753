import React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';

import { LOGIN_URL, SIGNUP_URL } from 'common/const';
import { navigateRedirect } from 'common/utils';

import { Toaster } from 'react-hot-toast';
import SEO from '@layout/SEO';
import { H3, P } from '@global/Texts';
import { MokensSignupButton } from '@global/Buttons';

import metaImgPath from 'images/meta-img-pre-register.png';
import brand from 'images/brand.png';

import './styles.scss';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Connect = ({ location }) => {
	const { t } = useTranslation();
	const { language, languages } = useI18next();

	return (
		<>
			<SEO
				title={t('comingSoon.pageTitle')}
				description={t('comingSoon.pageDescription')}
				lang="en"
				imgPath={metaImgPath}
				imgAlt=""
				meta={[]}
			/>
			<main className="passwordless-connect">
				<Toaster />
				<a href="/" className="logo-link">
					<img width="112px" src={brand} alt="Mokens League Logo" />
				</a>
				<div className="blue-bg">
					{location.state && location.state.flow && (
						<div className="sign-up-flow relative">
							<div className="link">
								<div className="link-header">
									<H3 className="py-2 text-center">
										{t(`passwordlessSignUp.link.${location.state.flow}.title`)}
									</H3>
								</div>
								<div className="link-content py-12">
									<div className="flex flex-col gap-4">
										<P>
											{t(
												`passwordlessSignUp.link.${location.state.flow}.description`
											)}
										</P>
										<P>
											{t(
												`passwordlessSignUp.link.${location.state.flow}.description2`
											)}
										</P>
										<MokensSignupButton
											className="link-button"
											text={t(
												`passwordlessSignUp.link.${location.state.flow}.linkButton`
											)}
											variant="primary"
											color="yellow"
											onClick={() => navigate(navigateRedirect(LOGIN_URL, language, languages))}
											size="sm"
										></MokensSignupButton>
										{location.state.flow === 'login' && (
											<MokensSignupButton
												className="link-button"
												text={t(
													`passwordlessSignUp.link.${location.state.flow}.signupButton`
												)}
												variant="secondary"
												color="yellow"
												onClick={() => navigate(navigateRedirect(SIGNUP_URL, language, languages))}
												size="sm"
											></MokensSignupButton>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
};

export default Connect;
