import {
	POLYGON_NETWORK_CHAIN_ID,
	POLYGON_NETWORK_CHAIN_ID_HEX,
} from './const';

import toast from 'react-hot-toast';
import { addEmailToSendpulse } from 'common/server';

export const isCompatibleNetwork = (currentNetworkId: string | number) => {
	return (
		currentNetworkId == POLYGON_NETWORK_CHAIN_ID ||
		currentNetworkId == POLYGON_NETWORK_CHAIN_ID_HEX
	);
};

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export const trimString = (baseString: string, limit: number) => {
	if (baseString.length > limit) return `${baseString.slice(0, limit)}...`;
	else return baseString;
};

export const displayWallet = (wallet: string) => {
	return `${wallet.slice(0, 7)}...${wallet.slice(-7)}`;
};

const SLICE_LENGTH = 8;

export const formatAddress = (address: string): string =>
	`${address.slice(0, SLICE_LENGTH)}...${address.slice(-SLICE_LENGTH, -1)}`;

export const openUrlAsSelf = (url: string) => {
	const a = document.createElement('a');
	a.href = url;
	a.target = '_self';
	document.body.appendChild(a);
	a.click();
	a.remove();
};

export const submitRegistration = async (emailAddress: string, selectedDevice: string, translate: (string) => string, referCode?: string ) => {
	if (!validateEmail(emailAddress)) {
		toast.error(translate('registerBeta.invalidEmailDescription'));
		return;
	}

	if (!selectedDevice) {
		toast.error(translate('registerBeta.noDeviceSelected'));
		return;
	}

	const emailBody = await addEmailToSendpulse({
		email: emailAddress,
		device: selectedDevice,
		referCode: referCode || 'mks_landing'
	});
	if (!emailBody) {
		toast.error(translate('registerBeta.serverError'));
		return;
	}
	toast.success(translate('registerBeta.emailAdded'));
	return true;
}

export const getLocalImage = (data:any, relativePath: string, dataType: 'imageData' | 'fluidData') => {
  const foundNode = data.allFile.edges.find(n => n.node.relativePath === relativePath).node;

  if(!foundNode) {
    return;
  }

  return dataType === 'imageData' ? foundNode.childImageSharp.gatsbyImageData : foundNode.childImageSharp.fluid;
}

export const containsSubstring = (mainString: string, searchString: string) => {
  const regex = new RegExp(searchString, 'i');
  return regex.test(mainString);
}

export const getBucketImage = (data:any, relativePath: string, dataType: 'imageData' | 'fluidData') => {
	const foundNode = data.allS3Object.nodes.find(node => containsSubstring(node.localFile?.relativePath, relativePath));

  if(!foundNode) {
    return;
  }

  return dataType === 'imageData' ? foundNode.localFile.childImageSharp.gatsbyImageData : foundNode.localFile.childImageSharp.fluid;
}

export const average = (numberArray: number[]) => {
	const totalSum = numberArray.reduce(
		(accumulator, currentValue) => accumulator + currentValue
	);
	return Math.round(totalSum/numberArray.length);
};

export const isBrowser = () => typeof window !== "undefined"

export const addListener = (event:string, cb: () => void, options?: object) => {
	if (!isBrowser) { return; }
	window.addEventListener(event, cb, options)
}

export const navigateRedirect = (to:string, currLang:string, availableLangs:string[]) => {
	return currLang === availableLangs[0]
		? `${to}`
		: `/${currLang}${to}`
}

export	const renderFullDate = (date, lang) => {
	return lang === 'en'
		? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
		: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}