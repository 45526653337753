import React, { FC } from "react";

import { ISEOParams } from "common/types";

import { Helmet } from "react-helmet";

const siteUrl = process.env.GATSBY_VERCEL_URL
  ? `https://${process.env.GATSBY_VERCEL_URL}`
  : process.env.GATSBY_SITE_URL;

const SEO: FC<ISEOParams> = ({
  title,
  description,
  lang = "en",
  meta,
  imgPath,
  imgAlt,
}) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    meta={[
      {
        name: "description",
        content: description,
      },
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        property: "og:image",
        content: siteUrl + imgPath,
      },
      {
        property: "og:image:alt",
        content: imgAlt,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: siteUrl,
      },
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:title",
        content: title,
      },
      {
        name: "twitter:description",
        content: description,
      },
      {
        name: "twitter:image",
        content: siteUrl + imgPath,
      },
      {
        name: "twitter:image:alt",
        content: imgAlt,
      },
      {
        property: "twitter:url",
        content: siteUrl,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
    ].concat(meta)}
  />
);

export default SEO;
